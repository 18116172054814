import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import { withPrefix } from 'gatsby';
import { FaCamera, FaMapMarker, FaPhone } from 'react-icons/fa/index';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/uk';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { isAdmin } from '../../utils';
import TrainDetails from './TrainDetails';
import Avatar from '@material-ui/core/Avatar';
import Skycons from './skycons';
import './card.scss';
import './ribon.css';
import locations from './locations';
import { getAdjustedPrice } from '../../BuisenessLogic/Trainings';

const styles = {
  card: {
    maxWidth: 350,
    margin: 10,
    minWidth: 350,
    flex: 1,
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: '#202527',
    color: 'white'
  },
  media: {
    objectFit: 'cover',
    color: 'white'
  },
  button: {
    cursor: 'default'
  }
};

const Overlay = styled.h1`
  text-shadow: 0px 3px 12px rgb(0, 0, 0);
  position: absolute;
  color: white;
  font-size: 25px;
  width: 100$;
  text-align: center;
  width: 100%;
  margin-top: 0.3em;
  div:first-child {
    opacity: 1;
  }
`;

const Label = styled.span`
  background: rgb(199, 19, 9);
  color: white;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bold;
  opacity: 0.9;
`;
const Badge = styled.img`
  position: absolute;
  right: -1px;
  width: 12px;
  top: -3px;
`;

class ImgMediaCard extends Component {
  state = { open: false, trainDetails: false, forecast: { icon: '' } };

  componentDidMount() {
    const { train = {} } = this.props;
    const { start } = train;
    const date = start.getTime() / 1000;
    const apiURL = `https://shiny-manatee-fe01a0.netlify.app/test?50.340771,30.551846,${date}`;

    fetch(apiURL)
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ forecast: data.currently });
      })
      .catch(e => console.log(e));
  }

  prompt = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  toggleDetails = (toggle = !this.state.trainDetails) => {
    this.setState({ trainDetails: toggle });
  };
  handleSave = () => {
    this.setState({ open: false });
    const dataToSave = { id: this.props.train.id };
    if (this.state.freeBikes) {
      dataToSave.freeBikes = this.state.freeBikes;
    }
    if (this.state.time) {
      dataToSave.Date = this.state.time;
    }
    if (this.state.place) {
      dataToSave.place = this.state.place;
    }
    if (this.state.price) {
      dataToSave.price = this.state.price;
    }
    if (this.state.image) {
      dataToSave.image = this.state.image;
    }
    if (this.state.route) {
      dataToSave.route = this.state.route;
    }
    if (this.state.name) {
      dataToSave.name = this.state.name;
    }
    if (this.state.level) {
      dataToSave.level = this.state.level;
    }
    this.props.editTrain(dataToSave);
  };

  render() {
    const { classes, currentUser, goToTrain, train = {}, removeTrain, defaultImage } = this.props;
    const {
      resource = [],
      start,
      title,
      places,
      image,
      actual,
      price,
      place,
      route,
      level = 'm',
      volovodless = true,
      showCall
    } = train;
    const admin = isAdmin({ id: currentUser });
    const trainMarkedToVisit = resource.some(user => currentUser === user.id);
    const soldOut = places <= 0;

    const removeCurrentTrain = () => removeTrain(train.id);

    const showButton = (trainMarkedToVisit || !soldOut) && actual;
    const style = actual
      ? {}
      : {
          opacity: 0.7
        };
    const eventImage = image.includes('graph') ? defaultImage : image || defaultImage;
    let placesText = 'місць';
    if (places < 5 && places > 1) {
      placesText = 'місця';
    } else if (places === 1) {
      placesText = 'місце';
    }
    const time = moment(start)
      .locale('uk')
      .format('HH:mm dddd ');

    const stylesForLevels = { color: 'black', fontWeight: 800, opacity: 0.8 };
    const coordinates = locations[place];
    const participants = volovodless
      ? resource
      : [
          {
            username: 'Дмитро Воловод',
            photoURL: 'https://graph.facebook.com/2228522020758630/picture',
            id: '4exSLu2TV8YY71fiTFWC6RHb0z32',
            rideFrom: 'Оболонь'
          },
          ...resource
        ];

    return (
      <>
        <Card className={classes.card} style={style} id={'trainings'}>
          <Overlay onClick={() => this.toggleDetails(true)}>
            <div>{time}</div>
            <div>
              {places} {placesText}
            </div>
            {this.state.forecast && this.state.forecast.apparentTemperature && (
              <>
                <Skycons
                  color="white"
                  icon={this.state.forecast.icon}
                  autoplay={true}
                  style={{
                    width: '25%',
                    borderRadius: '100%',
                    background: '#00000026',
                    padding: 3
                  }}
                />
                <div style={{ fontSize: 22 }}>
                  {' '}
                  {fahToCel(this.state.forecast.apparentTemperature)}
                </div>
              </>
            )}
          </Overlay>
          {level === 'l' && (
            <div
              className="corner-ribbon top-right orange"
              style={{ ...stylesForLevels, backgroundColor: 'orange' }}
            >
              L 31-34 км/г
            </div>
          )}
          {level === 'm' && (
            <div className="corner-ribbon top-right green" style={stylesForLevels}>
              M 29-31 км/г
            </div>
          )}
          {level === 's' && (
            <div style={stylesForLevels} className="corner-ribbon top-right yellow">
              S 25-27 км/г
            </div>
          )}
          {level === 'c' && (
            <div style={stylesForLevels} className="corner-ribbon top-right white">
              Cycle
            </div>
          )}
          <CardMedia
            component="img"
            alt="Зображення тренування"
            className={classes.media}
            height="160"
            image={eventImage}
            title="Тренування"
            onClick={() => this.toggleDetails(true)}
          />
          <CardContent onClick={() => this.toggleDetails(true)} style={{ paddingBottom: '65px' }}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ textAlign: 'center', fontSize: '20px', fontWeight: 600 }}
            >
              {title}
            </Typography>
            <Typography component="ol">
              {participants.map(({ username, photoURL, id, rideFrom, abonement }) => {
                const abonementIsValid = abonement && moment(abonement.toDate()).isAfter(moment());
                const src = photoURL.includes('graph') ? photoURL + '?width=50' : photoURL;
                return (
                  <li
                    key={id}
                    onClick={() => {
                      window.location = `/rating?user=${id}`;
                    }}
                    style={{ cursor: 'pointer', fontSize: 15, padding: '1px 0px' }}
                  >
                    {photoURL && (
                      <span style={{ position: 'relative' }}>
                        <Avatar
                          src={src}
                          style={{
                            verticalAlign: 'middle',
                            width: 30,
                            height: 30,
                            display: 'inline-block',
                            marginRight: 2,
                            backgroundColor: 'white'
                          }}
                        />
                        {(abonementIsValid ||
                          username === 'Дмитрий Воловод' ||
                          username === 'Дмитро Воловод') && <Badge src={withPrefix('badge.svg')} />}
                      </span>
                    )}
                    {username}{' '}
                    {rideFrom && (
                      <Label
                        title={`${username} їде на тренування з ${rideFrom} й готовий взяти попутників`}
                      >
                        {rideFrom}
                      </Label>
                    )}
                  </li>
                );
              })}
            </Typography>

            <Typography component="p" style={{ textAlign: 'center', padding: 5 }}>
              {coordinates && (
                <>
                  <Button
                    onClick={e => e.stopPropagation()}
                    href={`http://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`}
                    target="_blank"
                    color="primary"
                    style={{ fontSize: 14, padding: '8px 19px' }}
                    title={'Перейти до Google maps'}
                  >
                    <span>
                      Точка старту: {coordinates.name}{' '}
                      <FaMapMarker className="icon icon-strava" style={{ width: 13, height: 13 }} />
                    </span>
                  </Button>
                </>
              )}
            </Typography>

            <Typography component="p" style={{ textAlign: 'center', padding: '0 5px;' }}>
              {route && (
                <Button
                  onClick={e => e.stopPropagation()}
                  href={route}
                  target="_blank"
                  color="primary"
                  style={{ fontSize: 14, marginRight: 5 }}
                  title={'Перейти у Strava'}
                >
                  Маршрут
                  <svg className="icon icon-strava" style={{ height: 15, marginLeft: 5 }}>
                    <use xlinkHref={withPrefix('assets/img/sprite/sprite.svg#ic-strava')} />
                  </svg>
                </Button>
              )}
              {showCall && (
                <Button
                  onClick={e => e.stopPropagation()}
                  href={'https://us04web.zoom.us/j/5728628868?pwd=eXFmajJPa0ZybTJBbzlhbnJBUVBrQT09'}
                  target="_blank"
                  color="primary"
                  style={{ fontSize: 14 }}
                  title={'Zoom call'}
                >
                  Zoom call{' '}
                  <FaPhone className="icon icon-strava" style={{ height: 15, marginLeft: 5 }} />
                </Button>
              )}
            </Typography>
          </CardContent>

          <CardActions
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              bottom: '0',
              position: 'absolute',
              width: '100%',
              padding: ' 10px '
            }}
          >
            {showButton ? (
              <Button
                style={{
                  fontSize: 10,
                  color: '#c61107',
                  backgroundColor: '#c61107',
                  fontWeight: 800
                }}
                size="big"
                color="#c61107"
                onClick={() => {
                  currentUser ? goToTrain(train, currentUser) : navigate('/signin');
                }}
              >
                {trainMarkedToVisit ? 'Скасування' : 'Тренуватись'}
              </Button>
            ) : (
              <span />
            )}

            <Typography
              component="span"
              style={{ fontSize: '16px', fontWeight: 500, fontStyle: 'italy' }}
            >
              {moment(start).format('DD MMMM')},
              {admin && (
                <span>
                  <IconButton aria-label="Delete" onClick={removeCurrentTrain}>
                    <DeleteIcon style={{ fontSize: 18 }} />
                  </IconButton>
                  <IconButton aria-label="edit" onClick={this.prompt}>
                    <EditIcon style={{ fontSize: 18 }} />
                  </IconButton>
                </span>
              )}
              {price && <span> {getAdjustedPrice(price, start)} грн</span>}
            </Typography>
          </CardActions>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>Редагувати тренування</DialogContentText>
              <TextField
                defaultValue={moment(start).format('HH:mm:ss.SSS')}
                autoFocus
                margin="dense"
                id="time"
                label="Час"
                type="time"
                fullWidth
                onChange={e => {
                  const hour = parseInt(e.target.value.split(':')[0]);
                  const minute = parseInt(e.target.value.split(':')[1]);
                  const time = moment(start)
                    .set({ hour, minute })
                    .toDate();
                  this.setState({ time });
                }}
              />
              <TextField
                defaultValue={title}
                margin="dense"
                id="name"
                label="назва"
                type="text"
                fullWidth
                onChange={e => this.setState({ name: e.target.value })}
              />
              <TextField
                defaultValue={places}
                margin="dense"
                id="places"
                label="Кількість велосипедів"
                type="text"
                fullWidth
                onChange={e => this.setState({ freeBikes: e.target.value })}
              />
              <TextField
                defaultValue={place}
                margin="dense"
                id="place"
                label="Локація:"
                type="text"
                fullWidth
                onChange={e => this.setState({ place: e.target.value })}
              />
              <TextField
                defaultValue={price}
                margin="dense"
                id="price"
                label="Ціна:"
                type="text"
                fullWidth
                onChange={e => this.setState({ price: e.target.value })}
              />
              <TextField
                defaultValue={image}
                margin="dense"
                id="price"
                label="Зображення:"
                type="text"
                fullWidth
                onChange={e => this.setState({ image: e.target.value })}
              />
              <TextField
                defaultValue={route}
                margin="dense"
                id="route"
                label="Маршрут:"
                type="text"
                fullWidth
                onChange={e => this.setState({ route: e.target.value })}
              />
              <TextField
                defaultValue={level}
                margin="dense"
                id="level"
                label="Рівень:"
                type="text"
                fullWidth
                onChange={e => this.setState({ level: e.target.value })}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleSave} color="primary">
                Ок
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
        <TrainDetails
          placesText={placesText}
          currentUser={currentUser}
          goToTrain={goToTrain}
          train={train}
          eventImage={eventImage}
          open={this.state.trainDetails}
          toggleDetails={this.toggleDetails}
          time={time}
          places={places}
        />
      </>
    );
  }
}

function fahToCel(fahrenheit) {
  var celsius = Math.round((fahrenheit - 32) * (5 / 9));
  return celsius > 0 ? `+ ${celsius}` : celsius;
}
ImgMediaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ImgMediaCard);
