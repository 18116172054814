import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';

class Agreement extends Component {
  render() {
    return (
      <div className="container" style={{ color: 'white' }}>
        <h1 style={{ color: 'white' }}>Договір-оферта на надання послуг</h1>
        <p>
          Цей Договір-оферта на надання послуг (надалі за текстом — Договір) являє собою відкриту
          пропозицію (Оферту) і регулює відносини між Фізичною особою — підприємцем Должиков Гліб
          Олексійович (надалі за текстом — Виконавець) з одного боку і Користувачем (надалі також
          іменований — Замовник) з іншого боку, які разом іменовані «Сторони».
        </p>
        1. ЗАГАЛЬНІ ПОЛОЖЕННЯ
        <br />
        1.1. Цей Договір (надалі — Договір) є публічною офертою.
        <br />
        1.2. Сторонами Договору є Виконавець, з одного боку, і Замовник, з іншого боку, надалі
        окремо — Сторона, а разом — Сторони.
        <br />
        1.3. Відповідно до Цивільного Кодексу України, у разі прийняття викладених в Оферті умов і
        оплати послуг фізична або юридична особа, яка робить акцепт цієї оферти, стає Замовником
        (акцепт оферти рівносильний укладенню Договору на умовах, викладених в Оферті).
        <br />
        1.4. Платні послуги згідно з цим Договором надаються Виконавцем для фізичних осіб, фізичних
        осіб — підприємців та юридичних осіб.
        <br />
        2. ПРЕДМЕТ ДОГОВОРУ
        <br />
        2.1. Згідно з даним Договором Виконавець надає послуги з організації й проведення
        спортивного заходу — групового тренування на велотренажері або велосипеді, а Замовник у свою
        чергу зобов'язується оплатити на умовах, передбачених цим Договором, а також на сайті
        http://volovod.com, такі послуги.
        <br />
        2.2. Цей Договір вважається прийнятим і укладеним між Сторонами з моменту внесення оплати за
        послуги, що надаються на умовах цього Договору.
        <br />
        2.3. Замовник погоджується с умовами Користувацької угоди й Політики конфіденційності,
        передбачених на сайті http://volovod.com, а також зобов'язується виконувати їх.
        <br />
        2.4. Виконавець має право в односторонньому порядку змінювати положення цього Договору,
        виклавши їх у новій редакції на сайті http://volovod.com.
        <br />
        3. ВАРТІСТЬ ПОСЛУГ І УМОВИ ОПЛАТИ
        <br />
        3.1. Вартість послуг, що надаються за цим Договором, наведена на сайті http://volovod.com.
        <br />
        3.2. Замовник оплачує Послуги на умовах 100% передоплати. Правила оплати описано на сайті
        http://volovod.com.
        <br />
        3.3. Послуги за цим Договором вважаються наданими належним чином, якісно і в повному обсязі
        в момент їх оплати Замовником згідно з цим розділом Договору.
        <br />
        4. АКЦЕПТ ОФЕРТИ І УКЛАДЕННЯ ДОГОВОРУ
        <br />
        4.1. Замовник виконує акцепт Оферти і укладання Договору шляхом попередньої оплати Послуг
        Виконавця, згідно з розділом 3 Договору.
        <br />
        5. ТЕРМІН ДІЇ ТА ЗМІНА УМОВ ОФЕРТИ
        <br />
        5.1. Оферта набуває чинності з моменту розміщення її на сайті http://volovod.com і діє до
        моменту відкликання Оферти Виконавцем.
        <br />
        5.2. Виконавець лишає за собою право внести зміни в умови Оферти (у тім числі у вартість)
        та/або відкликати Оферту в будь-який момент на свій розсуд. У разі внесення Виконавцем змін
        в Оферту, такі зміни набувають чинності з моменту розміщення зміненого тексту Оферти на
        сайті http://volovod.com, якщо інший термін вступу змін в силу не зазначено додатково при
        такому розміщенні.
        <br />
        6. ТЕРМІН ДІЇ ТА ЗМІНА ДОГОВОРУ
        <br />
        6.1. Акцепт Оферти Замовником, здійснений, згідно з п. 2.2 Оферти, створює Договір на умовах
        Оферти.
        <br />
        6.2. Договір набуває чинності з моменту акцепту Оферти Замовником і діє до моменту
        розірвання Договору.
        <br />
        7. РОЗІРВАННЯ ДОГОВОРУ
        <br />
        7.1. Договір може бути розірваний за згодою Сторін у будь-який час.
        <br />
        7.2. Виконавець має право розірвати договір в односторонньому порядку.
        <br />
        8. ВІДПОВІДАЛЬНІСТЬ ТА ОБМЕЖЕННЯ ВІДПОВІДАЛЬНОСТІ
        <br />
        8.1. За порушення умов Договору Сторони несуть відповідальність, встановлену Договором та /
        або чинним законодавством України
        <br />
        8.2. Замовник в повному обсязі несе відповідальність за достовірність відомостей, зазначених
        Замовником.
        <br />
        8.3. Беручи до уваги умови розділу 8 Оферти, Замовник зобов'язується своїми силами і за свій
        рахунок вирішувати спори і врегульовувати претензії третіх осіб щодо розміщуваних
        матеріалів, або відшкодовувати збитки (включаючи судові витрати), завдані Виконавцю в
        зв'язку з претензіями й позовами, підставою пред'явлення яких стало розміщення матеріалів
        Замовника.
        <br />
      </div>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Agreement));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
