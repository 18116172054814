import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as routes from '../../constants/routes';

const Rules = ({ onAgree }) => (
  <ul>
    <li>Вартість участи у тренуванні складає 250 грн</li>
    <li>При скасуванні відвідування за добу - повернення 100%</li>
    <li>При скасуванні відвідування менш, ніж за добу, повернення складає 50%</li>
    <li>При пропусканні заняття з Вашого рахунку списується повна вартість заняття</li>
    <li>Ви можете зареєструватися на одне тренування при балансі 0 грн</li>
    <li>Поповнити баланс можна на сайті банківською картою</li>
    <li>
      Телефон для зв'язку <a href="tel:+380509276677">0509276677</a> - Должиков Гліб
    </li>
    <div className="jumbotron">
      <ul style={{ color: 'black !important' }}>
        <b style={{ fontWeight: 800 }}>
          Для забезпечення адекватного рівня навантаження усі тренування поділено на три групи
          складності:
        </b>

        <br />
        <li>
          <b style={{ fontWeight: 800 }}>S</b> — група для новачків і тих, хто хоче вдосконалити
          техніку в комфортних умовах (повороти, спринти, харчування на ходу). Проводиться на
          Трухановому острові. Рекомендуємо як перше тренування з нами для спортсменів будь-якого
          рівня.{' '}
          <b>Середня швидкість тут складає 25-27 км на годину. Середня дистанція — 30-40 км.</b>
        </li>
        <li>
          <b style={{ fontWeight: 800 }}>M</b> — група для тих спортсменів-любителів, хто катається
          впевнено.{' '}
          <b>Середня швидкість тут складає 30-33 км на годину. Середня дистанція — 50-60 км.</b>{' '}
          Проводиться на шосе з машиною супроводу та запасними колесами.
        </li>
        <li>
          <b style={{ fontWeight: 800 }}>L</b> — група для тих спортсменів-любителів, хто катається
          добре.{' '}
          <b>Середня швидкість тут складає 34-36 км на годину. Середня дистанція — 70-100 км.</b>{' '}
          Розігруються спринти, роботи за машиною, роботи вгору. Проводиться на шосе з машиною
          супроводу.
        </li>
        <br />
        {/*<li>*/}
        {/*  Для відвідування будь-якого тренування можна взяти шосейний велосипед{' '}*/}
        {/*  <Link to="/прокат" style={{ color: 'red' }}>*/}
        {/*    напрокат*/}
        {/*  </Link>*/}
        {/*</li>*/}
      </ul>
    </div>

    <li>
      <b style={{ fontSize: 12 }}>
        Тренування на шосе являє собою спільний проїзд у групі певної відстані — від 40 до 100 км.
        При цьому проводиться консультація, корекція техніки й інтенсивності, виконуються
        різноманітні типи робіт, вивчається тактика проведення фінішу. Безпеку забезпечує наявність
        автомобіля супроводу й тренера, який модерує групу, її швидкість і манеру їзди.
      </b>
      <br />
    </li>
    <li style={{ fontSize: 10 }} hidden>
      Увага: використання даного інтернет-сервісу відбувається згідно з користувацькою угодою,
      прочитати й ознайомитися з якою можна за <Link to={routes.AGREEMENT}>посиланням</Link>.
    </li>
    <li style={{ fontSize: 10 }} hidden>
      У разі невикористання кошти, переведені на особистий рахунок на сайті volovod.com, можна
      повернути впродовж 14 днів після поповнення. Клієнти самостійно несуть відповідальність за
      докладені фізичні зусилля.
    </li>
    <li style={{ fontSize: 10 }} hidden>
      Сайкл-велотренування являє собою 60 хвилин інтенсивного навантаження на велотренажерах під
      постійним наглядом тренера, включає в себе консультацію з тренування й допомогу в налаштуванні
      тренажера.
    </li>
    <li style={{ fontSize: 10 }} hidden>
      Должиков Гліб надає послуги з проведення групових велотренувань й занять на велотренажерах —
      сайкл. Сплачені на сайті кошти можуть бути використані для оплати участі у велотренуваннях,
      які проводить ФОП Должиков Гліб. Середня тривалість тренування 60 хвилин, місце проведення:
      вулиця В'ячеслава Липинського, 15, Київ.
    </li>
    <button
      onClick={() => {
        window.scrollTo(0, 0);
        onAgree();
      }}
      style={{ marginTop: 20, fontSize: 32 }}
    >
      Погоджуюся
    </button>
  </ul>
);

export default Rules;
