import Helmet from 'react-helmet';
import React from 'react';
import { withPrefix } from 'gatsby';

export default ({
  title = 'Sigma Software - Unicorn | Велосипедні тренування на шосе!',
  description = 'Групові гонки, навчання їзди в групі, оренда шосейних велосипедів, підготовка до змагань, підготовка ло Ironman',
  seoImage = withPrefix('portfolio-5.jpg'),
  script
}) => (
  <Helmet
    title={title}
    description={description}
    meta={[
      { name: 'групові тренування ', content: 'персональні тренування ' },
      {
        name: 'Шосе, дорожній велосипед, тренування в Києві',
        content: 'оренда шосейних велосипедів'
      },
      {
        name: 'apple-mobile-web-app-capable',
        content: 'yes'
      }
    ]}
    link={[
      { rel: 'shortcut icon', type: 'image/png', href: withPrefix('favicon.ico') },
      { rel: 'apple-touch-startup-image', href: withPrefix('logo.png') },
      { rel: 'apple-touch-icon-precomposed', href: withPrefix('logo.png') }
    ]}
  >
    <meta property="og:image" content={seoImage} />

    <meta
      name="viewport"
      content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
    />
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-133015546-1" />
    <script type="text/javascript">{`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-133015546-1');
    `}</script>
    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990173585" />
    <script type="text/javascript">
      {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-990173585');
    `}
    </script>

    <script type="text/javascript">
      {`function gtag_report_conversion(url) {
      var callback = function () {
      if (typeof(url) != 'undefined') {
      window.location = url;
    }
    };
      gtag('event', 'conversion', {
      'send_to': 'AW-990173585/9YX6CMDdgswBEJGzk9gD',
      'transaction_id': '',
      'event_callback': callback
    });
      return false;
    }
    `}
    </script>

    {script}
  </Helmet>
);
